import styles from './index.module.css'
// import "./index.css"
import React, { useState, useRef, useEffect } from 'react'
import { PageHeader, Button, Table, Tag, Space, DatePicker } from 'antd'
import request from '../../util/request'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom'

export default function CollectCoupons() {
  const { RangePicker } = DatePicker
  let navigate = useNavigate()

  const childRef = useRef()
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [total, setTotal] = useState('')
  const [data, setData] = useState([])
  useEffect(() => {
    getTabData()
  }, [pageSize, pageNumber, startDate, endDate])

  // 获取表格数据
  const getTabData = () => {
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.banner.info',
        biz_content: JSON.stringify({
          begin: startDate,
          end: endDate,
          page: pageNumber,
          size: pageSize,
        }),
      })
      .then(res => {
        if (res.data.code == '10000') {
          let data = res.data.result.content.map((e, i) => {
            e.key = i
            return e
          })
          setData(data)
          setTotal(res.data.result.totalElements)
        }
      })
  }
  function goDetails(record) {
    navigate('/collectCoupons/collectCouponsDetails/', {
      state: { name: record.name },
    })
  }

  const columns = [
    {
      title: '券名称',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              goDetails(record)
            }}
          >
            {record.name}
          </a>
        )
      },
    },
    {
      title: '点击总量',
      key: 'clicknum',
      dataIndex: 'clicknum',
      sorter: (a, b) => a.clicknum - b.clicknum,
    },
    {
      title: '点击率',
      key: 'clickRate',
      dataIndex: 'clickRate',
      sorter: (a, b) => a.clickRate.slice(0, -1) - b.clickRate.slice(0, -1),
    },
    {
      title: '创建时间',
      key: 'createtime',
      dataIndex: 'createtime',
      sorter: (a, b) => new Date(a.createtime) - new Date(b.createtime),
    },
    {
      title: '最近点击时间',
      key: 'lastclicktime',
      dataIndex: 'lastclicktime',
      sorter: (a, b) => new Date(a.lastclicktime) - new Date(b.lastclicktime),
    },
  ]

  function subTitle() {
    return (
      <div style={{ fontSize: '20px', color: '#333333', fontWeight: '500' }}>
        <span>优惠券总数：</span>
        <span style={{ marginRight: '40px' }}>{total}</span>
        {/* <span  >当前在线数：</span>
        <span>180</span> */}
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <div className={styles.product}>
        <div className={styles.warp}>
          <PageHeader
            className="site-page-header"
            // title="用户管理"
            // breadcrumb={{ routes }}
            ghost={false}
            subTitle={subTitle()}
            // extra={[
            //   <RangePicker placeholder={["开始日期","结束日期"]} />,
            //   <Button type="primary" key="1" >Download CSV</Button>,
            // ]}
          />
          <Table
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              total: total,
              onChange: (page, pageSize) => {
                setPageSize(pageSize)
                setPageNumber(page)
              },
            }}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>

      {/* <Footer/> */}
    </div>
  )
}
