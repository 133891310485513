import styles from './detail.module.css'
import './detail.css'
import React, { useState, useRef, useEffect } from 'react'
import {
  PageHeader,
  Button,
  Table,
  Tag,
  Space,
  DatePicker,
  Avatar,
  Divider,
  Breadcrumb,
  Tabs,
} from 'antd'
import request from '../../util/request'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import Bill from './Bill'
import BillHeader from './BillHeader'
import { set } from '@antv/util'

export default function UserDetails() {
  const { TabPane } = Tabs
  const { RangePicker } = DatePicker
  let navigate = useNavigate()
  let location = useLocation()
  const childRef = useRef()
  const [userDetail, setUserDetail] = useState({})
  const [userInfo, setUserInfo] = useState({})
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [total, setTotal] = useState('')
  const [data, setData] = useState([])
  const [BillData, setBillData] = useState()
  const [visible, setVisible] = useState(true)
  const [flag, setFlag] = useState(true)
  const [stateId, setStateId] = useState(true)
  const [tabsState, setTabsState] = useState(null)

  useEffect(() => {
    if (location.state.id) {
      // getOldDetails(location.state.alipayUserId)
      getDetails(location.state.id)

      console.log(location.state)
      // getDetails(location.state.id)
      setTabsState(2)
    } else {
      // console.log(location.state)
      // getDetails(location.state.id)
      getOldDetails(location.state.alipayUserId)

      setTabsState(1)
    }
    get_list(location.state.id)
    setStateId(location.state)
    // console.log(location)
  }, [pageNumber, pageSize])

  // console.log(location, '090999')
  // 投递记录
  function getDetails(id) {
    setFlag(false)
    console.log(id)
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.user.register.userdetail',
        biz_content: JSON.stringify({
          userId: id,
          page: pageNumber,
          size: pageSize,
        }),
      })
      .then(res => {
        console.log(res)
        setFlag(true)
        if (!flag) return
        console.log(res)
        console.log(res.data.result.userInfo)
        setUserDetail(res.data.result.details)
        setUserInfo(res.data.result.userInfo)
        setTotal(res.data.result.details.total)
        let data = res.data.result.details.records.map((e, i) => {
          e.key = i
          return e
        })
        setData(data)
      })
  }
  // 投递老记录
  function getOldDetails(id) {
    setFlag(false)
    // console.log(id)
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.user.detail',
        biz_content: JSON.stringify({
          alipayUserId: id,
          page: pageNumber,
          size: pageSize,
        }),
      })
      .then(res => {
        setFlag(true)
        if (!flag) return
        console.log(res)
        console.log(res.data.result.userInfo)
        setUserDetail(res.data.result.details)
        setUserInfo(res.data.result.userInfo)
        setTotal(res.data.result.details.total)
        let data = res.data.result.details.records.map((e, i) => {
          e.key = i
          return e
        })
        setData(data)
      })
  }
  // 账单记录
  const get_list = id => {
    // console.log(id)
    request
      .new_post({
        method: 'bankcard/ul.back.pointDetail.userPointDetailPage',
        data: {
          userId: id,
          pageNumber,
          pageSize,
        },
      })
      .then(res => {
        console.log(res)
        setBillData(res.data.data)
      })
      .catch(res => {
        // console.log(res)
        setBillData(res.data.data)
      })
  }

  const columns = [
    {
      title: '用户编号',
      dataIndex: 'alipayUserId',
      key: 'key',
    },
    {
      title: '投递结果',
      dataIndex: 'waste',
      key: 'key',
      render: text => {
        let bc = 'linear-gradient(90deg, #1aa88a 0%, #62ccb7 100%)'
        let unbc = 'linear-gradient(90deg, #8e9eab 0%, #b9c4cb 100%)'
        return (
          <div
            style={{
              width: '98px',
              height: '30px',
              background: text == '塑料瓶' ? bc : unbc,
              lineHeight: '30px',
              textAlign: 'center',
              color: '#ffffff',
              borderRadius: '5px',
              fontSize: 14,
            }}
          >
            {text}
          </div>
        )
      },
    },
    {
      title: '投递机器',
      dataIndex: 'branchName',
      key: 'key',
    },
    {
      title: '投递时间',
      key: 'key',
      dataIndex: 'createtime',
    },
    {
      title: '领券信息',
      key: 'key',
      dataIndex: 'bannerDetails',
      render: text => {
        return text.toString()
      },
    },
  ]

  function Heade() {
    return (
      <div className={styles.headBox}>
        <div className={styles.user}>
          <Avatar
            size={64}
            src="https://sbag-small.oss-cn-huhehaote.aliyuncs.com/upload/img/web/ito/machine3.png"
          />
          <div
            style={{ fontSize: '20px', color: '#333333', fontWeight: '500' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ marginLeft: 60 }}>
                用户ID:
                {userInfo.userId ? userInfo.userId : userInfo.alipayUserId}
              </span>
              <span style={{ marginLeft: 60 }}>手机号:{userInfo.phone}</span>
              <span>废宝银行卡号:{userInfo.cardNo}</span>
            </div>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.box}>
            <div className={styles.title2}>投递塑料瓶率</div>
            <div className={styles.title3}>{userInfo.plasticRate}</div>
          </div>
          <Divider className={styles.divider} type="vertical" />
          <div className={styles.box}>
            <div className={styles.title2}>投递领券率</div>
            <div className={styles.title3}>{userInfo.bannerRate}</div>
          </div>
          <Divider className={styles.divider} type="vertical" />
          <div className={styles.box}>
            <div className={styles.title2}>注册时间</div>
            <div className={styles.title4}>{userInfo.createtime}</div>
          </div>
          <Divider className={styles.divider} type="vertical" />
          <div className={styles.box}>
            <div className={styles.title2}>最近投递时间</div>
            <div className={styles.title4}>{userInfo.delivertime}</div>
          </div>
        </div>
      </div>
    )
  }

  const header = () => {
    if (visible) {
      return { ...Heade() }
    } else {
      return <BillHeader stateId={stateId} data={BillData}></BillHeader>
    }
  }

  const tabs = () => {
    if (tabsState == '2') {
      console.log(tabsState)
      return (
        <Tabs
          defaultActiveKey="1"
          onChange={() => {
            setVisible(!visible)
          }}
        >
          <TabPane tab="投递记录" key="1">
            <Table
              pagination={{
                current: pageNumber,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                total: total,
                onChange: (page, pageSize) => {
                  setPageSize(pageSize)
                  setPageNumber(page)
                },
              }}
              columns={columns}
              dataSource={data}
            />
          </TabPane>
          <TabPane tab="账单记录" key="2">
            <Bill data={BillData}></Bill>
          </TabPane>
        </Tabs>
      )
    } else if (tabsState == '1') {
      // console.log(tabsState)
      return (
        <Tabs
          defaultActiveKey="1"
          onChange={() => {
            setVisible(!visible)
          }}
        >
          <TabPane tab="投递记录" key="1">
            <Table
              pagination={{
                current: pageNumber,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                total: total,
                onChange: (page, pageSize) => {
                  setPageSize(pageSize)
                  setPageNumber(page)
                },
              }}
              columns={columns}
              dataSource={data}
            />
          </TabPane>
        </Tabs>
      )
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.product}>
        <div className={styles.warp}>
          <PageHeader
            className="site-page-header"
            // title="用户详情"
            breadcrumb={
              <Breadcrumb>
                <Breadcrumb.Item
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  <a>用户管理</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>用户详情</Breadcrumb.Item>
              </Breadcrumb>
            }
            ghost={false}
            // subTitle={subTitle()}
            extra={header()}
          />
          {tabs()}

          {/* <Tabs
            defaultActiveKey="1"
            onChange={() => {
              setVisible(!visible)
            }}
          >
            <TabPane tab="投递记录" key="1">
              <Table
                pagination={{
                  current: pageNumber,
                  pageSize: pageSize,
                  pageSizeOptions: [10, 20, 50, 100],
                  total: total,
                  onChange: (page, pageSize) => {
                    setPageSize(pageSize)
                    setPageNumber(page)
                  },
                }}
                columns={columns}
                dataSource={data}
              />
            </TabPane>
            <TabPane tab="账单记录" key="2">
              <Bill data={BillData}></Bill>
            </TabPane>
          </Tabs> */}
        </div>
      </div>
      {/* <Footer/> */}
    </div>
  )
}
