import styles from '../Home.module.css'
import React, { useState, useRef, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom'
import * as echarts from 'echarts'
import DateSelector from '../../component/DateSelector/DateSelector'
import request from '../../../util/request'
import { Table, Tag, Space, Calendar } from 'antd'
export default function DeliveryBox() {
  const date_selector_ref = useRef()
  let navigate = useNavigate()
  const [data, setData] = useState([])
  const [data1, setData1] = useState([])
  const [tdsj, setTdsj] = useState({})
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isall, setIsAll] = useState(true)
  useEffect(() => {
    defaultDate()
  }, [])

  // 打开日期选择器
  const openDateSelector = () => {
    date_selector_ref.current.showModal()
  }
  // 点击全部
  function defaultDate() {
    setIsAll(true)
    let value = { startDate: null, endDate: null }
    setStartDate(value.startDate)
    setEndDate(value.endDate)
    getTdData(value)
    getTabData(value)
  }
  // 选择日期后回调返回开始和结束时间
  function getDateBack(value) {
    setIsAll(false)
    setStartDate(value.startDate)
    setEndDate(value.endDate)
    getTdData(value)
    getTabData(value)
  }
  // 获取表格数据
  const getTabData = value => {
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.waste.info',
        biz_content: JSON.stringify({
          begin: value.startDate,
          end: value.endDate,
          page: '1',
          size: '8',
        }),
      })
      .then(res => {
        if (res.data.code == '10000') {
          let data = res.data.result.content.map((e, i) => {
            e.key = i
            return e
          })
          setData(data)
        }
      })
  }
  const columns = [
    {
      title: '用户编号',
      dataIndex: 'alipayUserId',
      key: 'alipayUserId',
    },
    {
      title: '投递结果',
      dataIndex: 'waste',
      key: 'waste',
      render: text => {
        let bc = 'linear-gradient(90deg, #1aa88a 0%, #62ccb7 100%)'
        let unbc = 'linear-gradient(90deg, #8e9eab 0%, #b9c4cb 100%)'
        return (
          <div
            style={{
              width: '98px',
              height: '30px',
              background: text == '塑料瓶' ? bc : unbc,
              lineHeight: '30px',
              textAlign: 'center',
              color: '#ffffff',
              borderRadius: '5px',
              fontSize: 14,
            }}
          >
            {text}
          </div>
        )
      },
    },
    {
      title: '机器投递',
      dataIndex: 'branchName',
      key: 'branchName',
    },
    {
      title: '投递时间',
      key: 'createtime',
      dataIndex: 'createtime',
      sorter: (a, b) => new Date(a.createtime) - new Date(b.createtime),
    },
  ]
  // 饼图
  const tdjg_option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      // top: '5%',
      // left: 'center',
      bottom: '5%',
      //   orient: 'vertical',
      // left: 'left'
    },
    series: [
      {
        name: '投递结果',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '20',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: data1,
      },
    ],
  }
  function tdjgInit() {
    let chartDom = document.getElementById('tdjg')
    let myChart = echarts.init(chartDom)
    tdjg_option && myChart.setOption(tdjg_option)
  }
  useEffect(() => {
    tdjgInit()
  }, [data1])
  function getTdData(value) {
    request
      .post({
        method: 'iot.user.delivery.result',
        biz_content: JSON.stringify({
          startDate: value.startDate,
          endDate: value.endDate,
        }),
      })
      .then(res => {
        if (res.data.code == '10000') {
          let result = res.data.result
          let total = 0
          let data = {
            plastic: '',
            nonPlastic: '',
            p_percentage: '',
            non_p_percentage: '',
          }
          let list = result.map((item, index) => {
            total += item.bottleNumber
            item.name = item.sortName
            item.value = item.bottleNumber
            if (item.sortName == '塑料') {
              data.plastic = item.bottleNumber
              item.itemStyle = { color: '#56c6b0' }
            }
            if (item.sortName == '非塑料') {
              data.nonPlastic = item.bottleNumber
              item.itemStyle = { color: '#dae0e4' }
            }
            return item
          })
          data.p_percentage = ((data.plastic / total) * 100).toFixed(2)
          data.non_p_percentage = ((data.nonPlastic / total) * 100).toFixed(2)
          setData1(list)
          setTdsj(data)
        }
      })
  }

  return (
    <>
      <div className={styles.deliveryBox}>
        <div className={styles.left}>
          <div className={styles.titleBox}>
            <div className={styles.title}>投递记录</div>
            <div
              onClick={() => {
                navigate('/deliveryRecord/')
              }}
              className={styles.more}
            >
              查看更多
            </div>
          </div>
          <div className={styles.tablebox}>
            <Table
              columns={columns}
              size="small"
              pagination={false}
              dataSource={data}
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.head}>
            <div className={styles.title}>投递结果</div>
            <div className={styles.date}>
              <div onClick={defaultDate} className={styles.allbox}>
                <div className={styles.all}>{isall ? <div></div> : null}</div>
                <span>全部</span>
              </div>
              <img
                src="https://sbag-small.oss-cn-huhehaote.aliyuncs.com/upload/img/web/ito/rl.png"
                alt=""
              />
              <span onClick={openDateSelector}>
                {!isall ? startDate + '至' + endDate : '日期选择'}
              </span>
            </div>
          </div>
          {/* charts */}
          <div className={styles.tdjgBox}>
            <div className={styles.bfb}>
              <div>{tdsj.non_p_percentage || 0}%</div>
              <div className={styles.count_l}>{tdsj.nonPlastic || 0}个</div>
            </div>
            <div className={styles.tdjg} id="tdjg">
              {' '}
            </div>
            <div className={styles.bfb}>
              <div>{tdsj.p_percentage || 0}%</div>
              <div className={styles.count_r}>{tdsj.plastic || 0}个</div>
            </div>
          </div>
        </div>
      </div>
      <DateSelector ref={date_selector_ref} getDateBack={getDateBack} />
    </>
  )
}
