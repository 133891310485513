
import styles from "../Home.module.css"
import React, { useState, useRef, useEffect } from 'react'
import Delivery from "./Delivery";
import UserLine from "./UserLine";
import CollectCoupons from "./CollectCoupons";
export default function Board() {
  useEffect(() => {

  }, [])

  return (
    <div className={styles.board}>
      {/* 投递数据 */}
      <Delivery />
      {/* 用户数据 */}
      <UserLine />
      {/* 领券数据   */}
      <CollectCoupons />
    </div>
  )

}