import React from 'react'
import Unregistered from './unregistered'
import Registered from './Registered'
import { Tabs } from 'antd'
const { TabPane } = Tabs

export default function index() {
  return (
    <div style={{ maxWidth: '1750px', margin: '0 auto' }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="已注册" key="1">
          <Registered></Registered>
        </TabPane>
        <TabPane tab="未注册" key="2">
          <Unregistered></Unregistered>
        </TabPane>
      </Tabs>
    </div>
  )
}
