import Home from '../pages/Home/Home'
import UserList from '../pages/UserList/index'
import UserDetails from '../pages/UserList/detail'
import LogIn from '../pages/LogIn/LogIn'
import Delivery from '../pages/Delivery/Delivery'
import DeliveryDetails from '../pages/Delivery/detail'
import CollectCoupons from '../pages/CollectCoupons/CollectCoupons'
import CollectCouponsDetails from '../pages/CollectCoupons/detail'
import Device from '../pages/device/Device'
import DeviceDetail from '../pages/device/detail'

const router = [
  // 登录页
  {
    path: '/',
    component: LogIn,
    auth: false,
  },
  //首页
  {
    path: '/home/',
    component: Home,
    auth: true,
  },
  // 用户列表
  {
    path: '/user/userList/',
    component: UserList,
    auth: true,
  },
  // 用户详情
  {
    path: '/user/detail/',
    component: UserDetails,
    auth: true,
  },
  // 设备列表
  {
    path: '/device/',
    component: Device,
    auth: true,
  },
  // 设备详情
  {
    path: '/device/detail/',
    component: DeviceDetail,
    auth: true,
  },
  // 投递记录
  {
    path: '/deliveryRecord/',
    component: Delivery,
    auth: true,
  },
  // 投递记录详情
  {
    path: '/deliveryRecord/deliveryDetails/',
    component: DeliveryDetails,
    auth: true,
  },
  // 领券管理列表
  {
    path: '/collectCoupons/',
    component: CollectCoupons,
    auth: true,
  },
  // 领券详情
  {
    path: '/collectCoupons/collectCouponsDetails/',
    component: CollectCouponsDetails,
    auth: true,
  },
]
export default router
