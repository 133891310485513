import { Button, Col, Input, Row, Form, message, Checkbox,Spin  } from "antd";
import { Router, Route, Routes, useNavigate } from 'react-router-dom'

import styles from "./index.module.css";
import React, { useState, useEffect } from "react";
import request from '../../util/request';
const FormItem = Form.Item;

export default function LogIn(props) {
  let navigate = useNavigate();
  const [codeKey, setCodeKey] = useState(new Date().getTime().toString() + Math.floor(Math.random() * 100000));
  useEffect(() => {


  }, [])

  const [loding,setLoding] = useState(false)
  const [name, setName] = useState("");
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // 登录
  const onFinish = values => {
    setLoding(true)
    if (!values) {
      setLoding(false)
      return
    }
    request.post({
      'method': 'fws.backend.login',
      'biz_content': JSON.stringify({
        "codeKey": codeKey,
        "password": values.password,
        "vCode": values.vCode,
        "username": values.userName
      })
    }).then(res => {
      
      if (res.data.code == 10000) {
        localStorage.setItem("user", JSON.stringify(res.data))
        getInfo();
      } else {
        setLoding(false)
        message.error(res.data.sub_msg);
      }
    }).catch((error) => {
      setLoding(false)
    })

  };

  const getInfo = () => {

    request.post({
      'method': 'fws.backend.fwsInfo',
    }).then(res => {
      if (res.data.code == 10000) {
        setLoding(false)
        if (res.data.result.role > 0) {
          async function fun0() {
            sessionStorage.setItem("info", JSON.stringify(res.data.result));
            return true
          }
          fun0().then(x => {
            navigate('/home/', { replace: true })
          })
        } else {
          message.error("账号不正确");
        }
      } else {
        setLoding(false)
        sessionStorage.removeItem("info");
        message.error(res.msg);
      }
    }).catch((error) => {
      setLoding(false)
    })

  }
  // 更新验证码
  const getCodeKey = () => {
    setCodeKey(
      new Date().getTime().toString() + Math.floor(Math.random() * 100000)
    );
  };

  return (
    <>
      
      <img className={styles.bg} src={request.imgUrl+"/upload/img/web/ito/loginbg.png"} alt="" />
      <div className={styles.box}>
      <Spin style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:99}} spinning={loding}  />
        <img className={styles.bg2} src={request.imgUrl+"/upload/img/web/ito/loginbg3.png"} alt="" />
        <Form
          autocomplete="off"
          name="basic"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        >
          <Form.Item
            wrapperCol={{ offset: 4, span: 16 }}
            name="userName"
            rules={[{ required: true, message: "请输入用户名!" }]}
          >
            <Input className={styles.inputs} placeholder="用户名" />
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 4, span: 16 }}
            label=""
            name="password"
            rules={[{ required: true, message: "请输入密码!" }]}
          >
            <Input.Password className={styles.inputs} placeholder="密码" />
          </Form.Item>
          <Form.Item label=""  wrapperCol={{ offset: 4, span: 16 }} name={"vCode"} rules={[{ required: true, message: "请输入验证码!" }]}>
            <Row gutter={8}>
              <Col span={16}>
                <Input className={styles.codeInputs} placeholder="验证码" />
              </Col>
              <Col span={8}>
                <img
                  onClick={() => {
                    getCodeKey();
                  }}
                  className={styles.codeImg}
                  src={request.api + "back/public/getCode?codeKey=" + codeKey}
                  alt=""
                />
              </Col>
            </Row>
          </Form.Item>

          {/* <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Checkbox>记住我</Checkbox>
        </Form.Item> */}

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button style={{width:"90px",height:'40px',borderRadius:"4px",fontSize:"14px",marginTop:"50px"}} type="primary" htmlType="submit">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>

    </>

  );
}
