import styles from './Home.module.css'
import React, { useState, useRef, useEffect } from 'react'
import * as echarts from 'echarts';
import Board from "./comment/Board"
import DeviceMap from "./comment/DeviceMap"
import DeliveryBox from "./comment/DeliveryBox"
import { Table, Tag, Space,Calendar  } from 'antd';
import request  from '../../util/request';
// 日期选择组件
import DateSelector from '../component/DateSelector/DateSelector';
export default function Home() {
  useEffect(() => {
    
  },[])
  const childRef = useRef();
  return (
    <div  className={styles.container}>
      
      <main className={styles.main}>
        <div className={styles.warp} >
          {/* 顶部数据看板 */}
          <Board/>
          {/* 地图模块 */}
          <DeviceMap/>
          {/* 投递看板 */}
          <DeliveryBox/>
        </div>
      </main>
    </div>
  )
}
