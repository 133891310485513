import styles from './detail.module.css'
import './detail.css'
import React, { useState, useRef, useEffect } from 'react'
import {
  PageHeader,
  Button,
  Table,
  Tag,
  Space,
  DatePicker,
  Avatar,
  Divider,
  Breadcrumb,
  Tabs,
} from 'antd'

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom'

export default function DeliveryDetails() {
  const { RangePicker } = DatePicker
  let navigate = useNavigate()
  const childRef = useRef()
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [total, setTotal] = useState('')
  useEffect(() => {}, [])
  const { TabPane } = Tabs
  function goDetails() {
    navigate('/user/detail/')
  }

  const columns = [
    {
      title: '用户编号',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '投递结果',
      dataIndex: 'age',
      key: 'age',
      render: text => {
        let bc = 'linear-gradient(90deg, #1aa88a 0%, #62ccb7 100%)'
        let unbc = 'linear-gradient(90deg, #8e9eab 0%, #b9c4cb 100%)'
        return (
          <div
            style={{
              width: '98px',
              height: '30px',
              background: bc,
              lineHeight: '30px',
              textAlign: 'center',
              color: '#ffffff',
              borderRadius: '5px',
              fontSize: 14,
            }}
          >
            塑料
          </div>
        )
      },
    },
    {
      title: '投递机器',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '投递时间',
      key: 'tags',
      dataIndex: 'tags',
    },
    {
      title: '领券信息',
      key: 'lingquan',
      dataIndex: 'lingquan',
    },
  ]

  const data = [
    {
      key: '1',
      name: '000000',
      age: '2021-12-13',
      address: '武汉工程大学泰塑公寓店',
      tags: '111',
    },
    {
      key: '2',
      name: '000000',
      age: '2021-12-13',
      address: '武汉工程大学泰塑公寓店',
      tags: '222',
      lingquan: 0.5,
    },
    {
      key: '3',
      name: '000000',
      age: '2021-12-13',
      address: '武汉工程大学泰塑公寓店',
      tags: '123',
    },
  ]
  function Heade() {
    return (
      <div className={styles.headBox}>
        <div className={styles.user}>
          <Avatar
            size={64}
            src="https://sbag-small.oss-cn-huhehaote.aliyuncs.com/upload/img/web/ito/machine3.png"
          />
          <div
            style={{ fontSize: '20px', color: '#333333', fontWeight: '500' }}
          >
            <span>用户总数：</span>
            <span>21850</span>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.box}>
            <div className={styles.title2}>投递塑料瓶率</div>
            <div className={styles.title3}>56%</div>
          </div>
          <Divider className={styles.divider} type="vertical" />
          <div className={styles.box}>
            <div className={styles.title2}>投递领券率</div>
            <div className={styles.title3}>88%</div>
          </div>
          <Divider className={styles.divider} type="vertical" />
          <div className={styles.box}>
            <div className={styles.title2}>注册时间</div>
            <div className={styles.title4}>2021/01/26 19：11</div>
          </div>
          <Divider className={styles.divider} type="vertical" />
          <div className={styles.box}>
            <div className={styles.title2}>最近投递时间</div>
            <div className={styles.title4}>2021/01/26 19：11</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.product}>
        <div className={styles.warp}>
          <PageHeader
            className="site-page-header"
            // title="用户详情"
            breadcrumb={
              <Breadcrumb>
                <Breadcrumb.Item
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  <a>用户管理</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>用户详情</Breadcrumb.Item>
              </Breadcrumb>
            }
            ghost={false}
            // subTitle={subTitle()}
            extra={Heade()}
          />
          <div className={styles.tableTitle}>投递记录</div>

          <Table
            rowKey={record => record.key}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: total,
              onChange: (page, pageSize) => {
                setPageSize(pageSize)
                setPageNumber(page)
              },
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '50'],
            }}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
      {/* <Footer/> */}
    </div>
  )
}
