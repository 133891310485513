
import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button, Divider, Dropdown, Menu, message, Input, Tabs, Modal, Select , Alert } from 'antd';
import style from "./../index.less"
const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 6 },
};
const validateMessages = {
  required: '${label} 必填',
  types: {
    email: '${label} is not validate email!',
    number: '${label} is not a validate number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
// 重新分配订单
const UpdateForm = (props) => {
  const { modalVisible, onCancel } = props;
  const actionRef = useRef();
  const [form] = Form.useForm();
  const [fileList1, setFileList1] = useState([])
  const [itemId, setItemId] = useState();
  const handleChange1 = (fileList) => {
    setFileList1(fileList)
  };
  const onChange = (() => {
  })
  useEffect(() => {
    if (props.modalVisible.id) {//设置编辑
      setItemId(props.modalVisible.id);
      console.log(props.modalVisible);
      let list_ = [];
      list_.push(props.modalVisible.operationName)
      form.setFieldsValue({
        list: list_,
      });
      getList();
    }else{
      form.resetFields();
    }
  }, [props.modalVisible.id]);
  
  const onFinish = values => {
    // props.dispatch({
    //   type: 'productSort/productEdit',
    //   payload: {
    //     ...dataConversion({
    //       "method": itemId?"fws.backend.reserveOrder.updateOperation":"",
    //       "biz_content": JSON.stringify({
    //         "reserveOrderId":itemId?itemId:'',
    //         "operationId": values.list,
    //       })
    //     })
    //   }
    // }).then((res) => {
    //   if (res.code == 10000) {
    //     message.success('提交成功');
    //     props.onCancel();
    //     props.reload();
    //     form.resetFields()
    //   } else {
    //     message.error(res.msg);
    //   }
    // })
  };
  
  //获取分配列表
  const [list, setlist] = useState([]);
  const getList = () => {
    // return props.dispatch({
    //   type: 'shop/orderDetails',
    //   payload: {
    //     ...dataConversion({
    //       'method': 'fws.backend.operation.list',
    //       "biz_content": JSON.stringify({
    //       })
    //     })
    //   }
    // }).then((res) => {
    //   setlist(res.result);
    // })
  }
  
  return (
    <Modal
      // destroyOnClose
      forceRender
      title="分配"
      visible={modalVisible}
      onOk={() => onFinish()}
      onCancel={() => props.onCancel()}
      reload={() => props.reload()}
      footer={null}
    >
      {/* {props.children} */}
      <Form form={form} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages} labelCol={{ span: 5 }}>
        <Form.Item name="list" label="选择分配" rules={[{ required: true }]}>
          <Select
            placeholder="请选择"
            allowClear
            style={{ width: '300px' }}
          >
            {list.map((item) => {
              return <Select.Option key={item.id} value={item.id}>{item.realName}</Select.Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            确定
            </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateForm;


