import styles from './index.module.css'
import './index.css'
import React, { useState, useRef, useEffect } from 'react'
import { PageHeader, Button, Table, Tag, Space, DatePicker } from 'antd'
import moment from 'moment'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom'
import request from '../../util/request'
export default function Delivery() {
  const { RangePicker } = DatePicker
  let navigate = useNavigate()
  const childRef = useRef()
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [total, setTotal] = useState('')
  const [data, setData] = useState([])
  useEffect(() => {
    getTabData()
  }, [pageSize, pageNumber, startDate, endDate])

  const changeDate = value => {
    if (value) {
      setStartDate(moment(value[0]._d).format('YYYY-MM-DD'))
      setEndDate(moment(value[1]._d).format('YYYY-MM-DD'))
    } else {
      setPageNumber(1)
      setStartDate(moment(null))
      setEndDate(moment(null))
    }
  }

  // 获取表格数据
  const getTabData = () => {
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.waste.info',
        biz_content: JSON.stringify({
          begin: startDate,
          end: endDate,
          page: pageNumber,
          size: pageSize,
        }),
      })
      .then(res => {
        if (res.data.code == '10000') {
          let data = res.data.result.content.map((e, i) => {
            e.key = i
            return e
          })
          setData(data)
          setTotal(res.data.result.totalElements)
        }
      })
  }

  const columns = [
    {
      title: '用户编号',
      dataIndex: 'alipayUserId',
      key: 'alipayUserId',
    },
    {
      title: '投递结果',
      dataIndex: 'waste',
      key: 'waste',
      render: text => {
        let bc = 'linear-gradient(90deg, #1aa88a 0%, #62ccb7 100%)'
        let unbc = 'linear-gradient(90deg, #8e9eab 0%, #b9c4cb 100%)'
        return (
          <div
            style={{
              width: '98px',
              height: '30px',
              background: text == '塑料瓶' ? bc : unbc,
              lineHeight: '30px',
              textAlign: 'center',
              color: '#ffffff',
              borderRadius: '5px',
              fontSize: 14,
            }}
          >
            {text}
          </div>
        )
      },
    },
    {
      title: '机器投递',
      dataIndex: 'branchName',
      key: 'branchName',
    },
    {
      title: '领券信息',
      dataIndex: 'bannerDetails',
      key: 'bannerDetails',
      render: (_, record) => _.toString(),
    },
    {
      title: '投递时间',
      key: 'createtime',
      dataIndex: 'createtime',
      sorter: (a, b) => new Date(a.createtime) - new Date(b.createtime),
    },
  ]

  function subTitle() {
    return (
      <div style={{ fontSize: '20px', color: '#333333', fontWeight: '500' }}>
        <span>总投递数量：</span>
        <span>{total}</span>
      </div>
    )
  }
  return (
    <div id="userlist">
      <div className={styles.product}>
        <div className={styles.warp}>
          <PageHeader
            className="site-page-header"
            // title="用户管理"
            // breadcrumb={{ routes }}
            ghost={false}
            subTitle={subTitle()}
            extra={[
              <RangePicker
                key="1"
                onChange={value => {
                  changeDate(value)
                }}
                placeholder={['开始日期', '结束日期']}
              />,
              <Button type="primary" key="2">
                Download CSV
              </Button>,
            ]}
          />
          <Table
            size="middle"
            rowKey={record => record.key}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,

              total: total,
              onChange: (page, pageSize) => {
                setPageSize(pageSize)
                setPageNumber(page)
              },
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '50'],
            }}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>

      {/* <Footer/> */}
    </div>
  )
}
