import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ConfigProvider} from 'antd'
import cn from 'antd/es/locale/zh_CN'
import { BrowserRouter } from 'react-router-dom'
ReactDOM.render(

  // 严格模式不取消在操作refs的时候会报警告错误
    // <React.StrictMode>
    <ConfigProvider locale={cn} >
      <BrowserRouter  >
        <App />
      </BrowserRouter>
      </ConfigProvider>
    // </React.StrictMode>
  ,
  document.getElementById('root')
);

