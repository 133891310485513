import styles from '../Home.module.css'
import React, { useState, useRef, useEffect } from 'react'
import * as echarts from 'echarts'
import DateSelector from '../../component/DateSelector/DateSelector'
import request from '../../../util/request'
import { getNearOneMonth, getNeerOneWeek } from '../../../util/getNearOneMonth'
export default function Delivery() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [data, setData] = useState({})
  const [timeList, setTimeList] = useState([])
  const [countList, setCountList] = useState([])
  const [isall, setIsAll] = useState(true)
  const date_selector_ref = useRef()
  useEffect(() => {
    defaultDate()
  }, [])
  useEffect(() => {
    lineInit()
  }, [timeList, countList])
  useEffect(() => {
    // getDelivery()
  }, [startDate, endDate])

  // 投递数据配置
  var td_option = {
    grid: {
      top: 10,
      bottom: 20,
      right: 22,
      left: 30,
    },
    xAxis: {
      type: 'category',
      data: timeList,
      boundaryGap: true,
      axisTick: {
        lineStyle: {
          width: 0,
        },
      },
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#FFFFFF', //坐标值得具体的颜色
        },
      },
      splitLine: {
        show: true, // X轴线 颜色类型的修改
        lineStyle: {
          type: 'dashed',
          color: '#42ad7b',
        },
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        textStyle: {
          color: '#FFFFFF', //坐标值得具体的颜色
          fontSize: 0, //设置为零隐藏文字
        },
      },
      splitLine: {
        show: false, // X轴线 颜色类型的修改
        lineStyle: {
          type: 'dashed',
          color: '#292c38',
        },
      },
    },
    color: '#fba798',
    gradientColor: '#fba798',
    textStyle: {
      color: '#FFFFFF',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
    },
    series: [
      {
        data: countList,
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 5,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 0.5, [
            { offset: 0, color: '#f087a0' },
            { offset: 1, color: '#ffc685' },
          ]),
        },
        // symbolSize: 0,
        // hoverSymbolSize:14,
        // 折线拐点的样式
        itemStyle: {
          normal: {
            // 静止时：
            // color: '#3aa7ff'
          },
          emphasis: {
            // 鼠标经过时：
            color: '#ffffff',
            borderColor: '#ffffff',
          },
        },
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ],
        // },
      },
    ],
  }
  function lineInit() {
    let chartDom = document.getElementById('tdsj')
    let myChart = echarts.init(chartDom)
    td_option && myChart.setOption(td_option)
  }
  // 选择日期后回调返回开始和结束时间
  function getDateBack(value) {
    setIsAll(3)
    setStartDate(value.startDate)
    setEndDate(value.endDate)
    getDelivery(value)
  }
  // 打开日期选择器
  const openDateSelector = () => {
    date_selector_ref.current.showModal()
  }
  // 点击全部
  function defaultDate() {
    setIsAll(1)
    let value = getNearOneMonth()
    setStartDate(value.startDate)
    setEndDate(value.endDate)
    getDelivery(value)
  }

  function defaultDateWeek() {
    setIsAll(2)
    let value = getNeerOneWeek()
    setStartDate(value.startDate)
    setEndDate(value.endDate)
    getDelivery(value)
  }
  // 根据时间获取投递数据
  const getDelivery = value => {
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.waste.total',
        biz_content: JSON.stringify({
          begin: value.startDate,
          end: value.endDate,
        }),
      })
      .then(res => {
        if (res.data.code == '10000') {
          let arr = [[], []]
          res.data.result.wasteDashbord.map(e => {
            arr[0].push(e.num)
            arr[1].push(e.date)
          })
          setTimeList(arr[1])
          setCountList(arr[0])
          setData(res.data.result)
        }
      })
  }
  return (
    <>
      <div className={styles.box1}>
        <div className={styles.head}>
          <div className={styles.title}>投递数据</div>
          <div className={styles.countBox}>
            <span style={{ marginRight: '20px' }}>
              今日投递次数：{data.todayWasteNum}
            </span>
            <span>总投递数量：{data.totalWasteNum}</span>
          </div>
          <div className={styles.date}>
            <div onClick={defaultDateWeek} className={styles.allbox}>
              <div className={styles.all}>
                {isall == 2 ? <div></div> : null}
              </div>
              <span>近一周</span>
            </div>
            <div onClick={defaultDate} className={styles.allbox}>
              <div className={styles.all}>
                {isall == 1 ? <div></div> : null}
              </div>
              <span>近一月</span>
            </div>
            <img
              src="https://sbag-small.oss-cn-huhehaote.aliyuncs.com/upload/img/web/ito/rl.png"
              alt=""
            />
            <span onClick={openDateSelector}>
              {isall == 3 ? startDate + '至' + endDate : '选日期'}
            </span>
          </div>
        </div>
        {/* chart */}
        <div className={styles.tdsj} id="tdsj">
          {' '}
        </div>
      </div>
      <DateSelector ref={date_selector_ref} getDateBack={getDateBack} />
    </>
  )
}
