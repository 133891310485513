import styles from './Header.module.css'
import React, { useState, useImperativeHandle, useEffect, Modal } from 'react'
import { useNavigate } from 'react-router-dom'
import request from '../../util/request'
function Header(props, { cRef }) {
  let navigate = useNavigate()
  const [navTitle, setNavTitle] = useState('/')
  const [isShowSearchBox, setIsShowSearchBox] = useState(false)
  const [num, setNum] = useState(0)
  useEffect(() => {
    setIsShowSearchBox(false)
  }, [])
  useImperativeHandle(cRef, () => ({
    // 把整个对象放在这里面暴露给父组件;
    isShowSearchBox,
    changeSearchBox: state => {
      setIsShowSearchBox(state)
    },
  }))
  useEffect(() => {
    let menu = window.location.pathname.match(/\/(\S*?)\//)
    setNavTitle(menu ? menu[1] : '/')
  })
  function changePage(e, i, event) {
    setNum(i)
    navigate(e.path)
  }
  const navData = [
    { title: '数据总览', path: '/home/', menu: 'home' },
    { title: '用户管理', path: '/user/userList/', menu: 'user' },
    { title: '设备管理', path: '/device/', menu: 'device' },
    { title: '投递记录', path: '/deliveryRecord/', menu: 'deliveryRecord' },
    { title: '领券管理', path: '/collectCoupons/', menu: 'collectCoupons' },
  ]

  const _style = {
    line: {
      width: '75px',
      height: '2px',
      background: 'linear-gradient(90deg, #8FF379 0%, #1CBDB4 100%)',
      position: 'absolute',
      left: num * 122 + 'px',
      bottom: '-2px',
      transition: '.25s all',
    },
  }
  return (
    <div className={styles.headerBox}>
      {/* 头部 */}
      <div className={styles.header}>
        <div className={styles.warp}>
          <img
            className={styles.logo}
            src={request.imgUrl + '/upload/img/web/ito/newlog.png'}
          ></img>
          <div id="searchIcon" className={styles.icons}>
            {/* <img className={styles.icon} onClick={(e) => {  setIsShowSearchBox(!isShowSearchBox)
                            setNavTitle('')
                        }} src={request.imgUrl+'/upload/img/web/ito/bell.png'} ></img> */}
            <img
              onClick={props.showModal}
              className={styles.icon}
              src={request.imgUrl + '/upload/img/web/ito/exit.png'}
            ></img>
          </div>
        </div>
      </div>
      {/* 导航栏 */}
      <div className={styles.navbox}>
        <div className={styles.warp}>
          <div className={styles.list}>
            {navData.map((e, i) => {
              return (
                <div
                  className={`${
                    e.menu === navTitle ? styles.item : styles.none
                  } ${navData.length - 1 === i ? styles.nmr : ''}`}
                  onClick={event => {
                    changePage(e, i, event)
                  }}
                  key={i}
                >
                  <span>{e.title}</span>
                </div>
              )
            })}
            <div style={_style.line}></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Header
