import styles from './index.module.css'
import React, { useState, useRef, useEffect } from 'react'
import { PageHeader, Button, Table, Tag, Space, DatePicker } from 'antd'
import request from '../../util/request'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom'
import moment from 'moment'
export default function Unregistered() {
  const { RangePicker } = DatePicker
  let navigate = useNavigate()
  const childRef = useRef()
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [data, setData] = useState([])
  const [list, setList] = useState([])

  useEffect(() => {
    getUserList()
  }, [pageSize, pageNumber, startDate, endDate])
  const getUserList = () => {
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.user.list',
        biz_content: JSON.stringify({
          begin: startDate,
          end: endDate,
          page: pageNumber,
          size: pageSize,
        }),
      })
      .then(res => {
        console.log(res)
        if (res.data.code == 10000) {
          let list = res.data.result.content.map((e, i) => {
            e.key = i
            return e
          })
          setList(list)
          setData(res.data.result)
        }
      })
  }
  function goDetails(record) {
    // navigate('/user/detail/', { state: { id: record.userId } })
    navigate('/user/detail/', { state: { alipayUserId: record.alipayUserId } })
  }
  const changeDate = value => {
    if (value) {
      setStartDate(moment(value[0]._d).format('YYYY-MM-DD'))
      setEndDate(moment(value[1]._d).format('YYYY-MM-DD'))
    } else {
      setStartDate(moment(null))
      setEndDate(moment(null))
    }
  }
  const columns = [
    {
      title: '用户编号',
      dataIndex: 'alipayUserId',
      key: 'key',
      render: (text, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              goDetails(record)
            }}
          >
            {text}
          </a>
        </Space>
      ),
    },
    {
      title: '注册时间',
      dataIndex: 'createtime',
      key: 'key',
      sorter: (a, b) => new Date(a.createtime) - new Date(b.createtime),
    },
    {
      title: '废宝银行卡号',
      dataIndex: 'cardNo',
      key: 'key',
    },
    {
      title: '银行卡经验值',
      dataIndex: 'experience',
      key: 'key',
    },
    {
      title: '常用投递机器',
      dataIndex: 'branchName',
      key: 'key',
    },
    {
      title: '投递塑料瓶率',
      key: 'key',
      dataIndex: 'plasticRate',
      sorter: (a, b) => a.plasticRate.slice(0, -1) - b.plasticRate.slice(0, -1),
    },
    {
      title: '投递领券率',
      key: 'key',
      dataIndex: 'bannerRate',
      sorter: (a, b) => a.bannerRate.slice(0, -1) - b.bannerRate.slice(0, -1),
    },
    {
      title: '最近投递时间',
      key: 'key',
      dataIndex: 'delivertime',
      sorter: (a, b) => new Date(a.delivertime) - new Date(b.delivertime),
    },
  ]
  function subTitle() {
    return (
      <div style={{ fontSize: '20px', color: '#333333', fontWeight: '500' }}>
        <span>用户总数：</span>
        <span>{data.totalElements}</span>
      </div>
    )
  }
  return (
    <div id="userlist" className={styles.container}>
      <div className={styles.product}>
        <div className={styles.warp}>
          <PageHeader
            className="site-page-header"
            ghost={false}
            subTitle={subTitle()}
            extra={[
              <RangePicker
                key="1"
                onChange={value => {
                  changeDate(value)
                }}
                placeholder={['开始日期', '结束日期']}
              />,
              <Button type="primary" key="2">
                Download CSV
              </Button>,
            ]}
          />
          <Table
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              total: data.totalElements,
              onChange: (page, pageSize) => {
                setPageSize(pageSize)
                setPageNumber(page)
              },
            }}
            columns={columns}
            dataSource={list}
          />
        </div>
      </div>

      {/* <Footer/> */}
    </div>
  )
}
