import styles from './detail.module.css'
import React, { useState, useRef, useEffect } from 'react'
import {
  PageHeader,
  Button,
  Table,
  Tag,
  Space,
  DatePicker,
  Avatar,
  Divider,
  Breadcrumb,
  Tabs,
} from 'antd'
import request from '../../util/request'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom'

export default function CollectCouponsDetails() {
  const { RangePicker } = DatePicker
  let navigate = useNavigate()
  let location = useLocation()
  const childRef = useRef()
  const [userDetail, setUserDetail] = useState({})
  const [bannerInfo, setBannerInfo] = useState({})
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [total, setTotal] = useState('')
  const [data, setData] = useState([])
  const { TabPane } = Tabs
  useEffect(() => {
    getDetails(location.state.name)
  }, [pageNumber, pageSize])
  function getDetails(name) {
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.banner.waste',
        biz_content: JSON.stringify({
          banner: name,
          begin: startDate,
          end: endDate,
          page: pageNumber,
          size: pageSize,
        }),
      })
      .then(res => {
        setUserDetail(res.data.result.details)
        setBannerInfo(res.data.result.bannerInfo)
        setTotal(res.data.result.details.totalElements)
        let data = res.data.result.details.content.map((e, i) => {
          e.key = i
          return e
        })
        setData(data)
      })
  }

  const columns = [
    {
      title: '用户编号',
      dataIndex: 'alipayUserId',
      key: 'key',
    },
    {
      title: '投递结果',
      dataIndex: 'waste',
      key: 'key',
      render: text => {
        let bc = 'linear-gradient(90deg, #1aa88a 0%, #62ccb7 100%)'
        let unbc = 'linear-gradient(90deg, #8e9eab 0%, #b9c4cb 100%)'
        return (
          <div
            style={{
              width: '98px',
              height: '30px',
              background: text == '塑料瓶' ? bc : unbc,
              lineHeight: '30px',
              textAlign: 'center',
              color: '#ffffff',
              borderRadius: '5px',
              fontSize: 14,
            }}
          >
            {text}
          </div>
        )
      },
    },
    {
      title: '投递机器',
      dataIndex: 'branchName',
      key: 'key',
    },
    {
      title: '投递时间',
      key: 'key',
      dataIndex: 'createtime',
      sorter: (a, b) => new Date(a.createtime) - new Date(b.createtime),
    },
  ]

  function Heade() {
    return (
      <div className={styles.headBox}>
        <div className={styles.user}>
          {/* <Avatar size={64} src="https://sbag-small.oss-cn-huhehaote.aliyuncs.com/upload/img/web/ito/machine3.png" />
                <div style={{fontSize:"20px",color:"#333333",fontWeight:"500"}} >
                    <span  >优惠券名称：</span>
                    <span>{bannerInfo.name}</span>
                </div> */}
        </div>
        <div className={styles.info}>
          <div className={styles.box}>
            <div className={styles.title2}>券名称</div>
            <div className={styles.title3}>{bannerInfo.name}</div>
          </div>
          <Divider className={styles.divider} type="vertical" />
          <div className={styles.box}>
            <div className={styles.title2}>点击量</div>
            <div className={styles.title3}>{bannerInfo.clickRate}</div>
          </div>
          <Divider className={styles.divider} type="vertical" />
          <div className={styles.box}>
            <div className={styles.title2}>创建时间</div>
            <div className={styles.title4}>{bannerInfo.createtime}</div>
          </div>
          <Divider className={styles.divider} type="vertical" />
          <div className={styles.box}>
            <div className={styles.title2}>最近点击时间</div>
            <div className={styles.title4}>{bannerInfo.lastclicktime}</div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <div className={styles.product}>
        <div className={styles.warp}>
          <PageHeader
            className="site-page-header"
            // title="用户详情"
            breadcrumb={
              <Breadcrumb>
                <Breadcrumb.Item
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  <a>领券管理</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>券点击详情</Breadcrumb.Item>
              </Breadcrumb>
            }
            ghost={false}
            // subTitle={subTitle()}
            extra={Heade()}
          />
          <div className={styles.tableTitle}>点击记录</div>
          <Table
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              total: total,
              onChange: (page, pageSize) => {
                setPageSize(pageSize)
                setPageNumber(page)
              },
            }}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
      {/* <Footer/> */}
    </div>
  )
}
