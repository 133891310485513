import { Upload, Modal,message } from 'antd';
import React, {useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import request from '../../../util/request';
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const UploadImg = (props) => {
    const [previewVisible,setPreviewVisible] = useState(false);
    const [previewImage,setPreviewImage] = useState("");
    const [previewTitle,setPreviewTitle] = useState("");
    const [previewType,setPreviewType] = useState("");
    const [fileList,setFileList] = useState([])
    const handleCancel = () => {
      var viedoBox = document.getElementById('videoBox')
      if(viedoBox){
        viedoBox.innerHTML=null
      }
      setPreviewVisible(false )
    };

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        }
        setPreviewVisible(true)
        setPreviewImage(file.url||file.preview  )
        setPreviewType(file.fileType)
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
        if(file.response.imgUrl.indexOf('.mp4')>=0){
          setTimeout(() => {
            var viedoBox = document.getElementById('videoBox')
            viedoBox.innerHTML=` <video id="video" autoplay="autoplay" width="100%" height="100%" controls>
            <source src=${file.url||file.preview}  type="video/mp4"/>
          </video>`
          },300)
        }
    };
    const handleChange = ({file,fileList}) => {
      fileList = fileList.map((file) => {
        console.log(file,"filefilefile")
        if (file.response && file.response.imgUrl.indexOf('.mp4')>=0)  {
          file.type = 'image/'
          file.fileType = "SCREEN_PICS" 
          file.thumbUrl=file.response.imgUrl+"?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto"
        }
       return file;
     });
    props.onChange(fileList)
  };
    const uploadButton = (
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">Upload</div>
        </div>
    );

    const upurl = process.env.NODE_ENV === 'development' ? "/back/upload/one" : "v20211010/back/upload/one"
    return(
        <div style={{width:'80%'}} className="clearfix">
            <Upload
            action={request.api+upurl}
            listType="picture-card"
            fileList={props.fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            >
            {props.fileList.length >= props.length ? null : uploadButton}
            </Upload>
            <Modal
            
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
            >
              {previewType == "SCREEN_PICS"?(
                <div id="videoBox" >
                  {/* <video id="video" width="100%" height="100%" controls>
                    <source src={previewImage}  type="video/mp4"/>
                  </video> */}
                </div>
              ):(
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              )}
            </Modal>
        </div>
    )



}

export default UploadImg
