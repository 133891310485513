

import React, { useState,useEffect,useImperativeHandle,forwardRef } from 'react';
import { Modal, Button, DatePicker, Form } from 'antd';
import styles from "./index.module.css";
import moment from "moment"
const { RangePicker } = DatePicker;

const DateSelector = forwardRef((props,ref) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  useImperativeHandle(ref,() => ({
    //暴露给父组件的方法
    showModal:()=>{
      setIsModalVisible(true);
    }
  }))

  useEffect(() => {
    form.resetFields()
  },[])
  const onFinish = (values) => {

    let startDate = moment(values.startDate).format("YYYY-MM-DD")
    let endDate = moment(values.endDate).format("YYYY-MM-DD")
    props.getDateBack({
      startDate,
      endDate,
    })
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal  title="日期选择" footer={null} visible={isModalVisible} forceRender   onCancel={handleCancel}  >
        <div className={styles.datePickerBox} >
          <Form
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label="开始日期"
              name="startDate"
              rules={[{ required: true, message: "请选择开始日期!" }]}
            >
              <DatePicker placeholder="请选择" />
              {/* <RangePicker /> */}
            </Form.Item>

            <Form.Item
              label="结束日期"
              name="endDate"
              rules={[{ required: true, message: "请选择结束日期!" }]}
            >
              <DatePicker placeholder="请选择" />
            </Form.Item>
            <Form.Item  >
              <div className={styles.btnbox} >
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </div>

            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  )

})

export default DateSelector;
