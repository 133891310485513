import {
  Button,
  Divider,
  message,
  Input,
  Form,
  Row,
  Col,
  Modal,
  Popconfirm,
  DatePicker,
  Select,
  Table,
  PageHeader,
  Space,
} from 'antd'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.css'
import CreateForm from './components/CreateForm'
import UpdateForm from './components/UpdateForm'
import { QuestionCircleOutlined } from '@ant-design/icons'
import request from '../../util/request'
import moment from 'moment'
const { Option } = Select
const FormItem = Form.Item
const formLayout = { labelCol: { span: 7 }, wrapperCol: { span: 13 } }
const Device = props => {
  let navigate = useNavigate()
  const { RangePicker } = DatePicker
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [cancelpreviewVisible, setcancelpreviewVisible] = useState(false)
  const [createModalVisible, handleModalVisible] = useState(false)
  const [updateModalVisible, handleModalVisibleUp] = useState(false)
  const [form] = Form.useForm()
  const [provinceList, setProvinceList] = useState([])
  const [cityList, setCityList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [provinceName, setProvinceName] = useState('')
  const [cityId, setCityId] = useState('')
  const [areaId, setAreaId] = useState('')
  const [record, setRecord] = useState('')
  const [lsitData, setListData] = useState([])
  const [overflowStatus, setOverflowStatus] = useState('')
  const [online, setOnline] = useState('')
  useEffect(() => {
    getAddressList(0, 'province')
    getDeviceList({})
  }, [
    pageSize,
    pageNumber,
    cityList,
    districtList,
    areaId,
    online,
    overflowStatus,
  ])

  // useEffect(() => {
  //   getDeviceList({})
  // }, [pageSize, pageNumber])
  // useEffect(() => {
  //   getDeviceList({})
  // }, [cityList, districtList, areaId])
  // useEffect(() => {
  //   getDeviceList({})
  // }, [online, overflowStatus])

  const list = () => {}

  const getAddressList = (id, level) => {
    request
      .post({
        method: 'area.allChildren',
        biz_content: JSON.stringify({
          id: id,
        }),
      })
      .then(res => {
        if (res.data.code == 10000) {
          if (level == 'province') {
            setProvinceList(res.data.result)
          } else if (level == 'city') {
            setCityList(res.data.result)
          } else if (level == 'district') {
            setDistrictList(res.data.result)
          }
        }
      })
  }
  const columns = [
    {
      title: '设备编号',
      hideInSearch: false,
      order: 1,
      dataIndex: 'branchCode',
      render: (text, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              navigate('/device/detail/', { state: { id: record.branchCode } })
            }}
          >
            {text}
          </a>
        </Space>
      ),
    },
    {
      title: '设备名称',
      hideInSearch: false,
      dataIndex: 'name',
      render: (_, record) => (
        <div style={{ maxWidth: '120px' }}>{record.name}</div>
      ),
    },

    {
      title: '设备状态',
      dataIndex: 'online',
      hideInSearch: true,
      maxWidth: 100,
      hideInForm: true,
      render: (_, record) =>
        record.online ? (
          <span style={{ color: '#219653' }}>在线</span>
        ) : (
          <span style={{ color: '#EB5757' }}>离线</span>
        ),
    },
    {
      title: '容量状态',
      dataIndex: 'overflowValue4pageChs',
      maxWidth: 100,
      render: (_, record) => {
        if (record.overflowValue4pageChs == '正常')
          return (
            <span style={{ color: '#219653' }}>
              {record.overflowValue4pageChs}
            </span>
          )
        if (record.overflowValue4pageChs == '即将满溢')
          return (
            <span style={{ color: '#EB5757' }}>
              {record.overflowValue4pageChs}
            </span>
          )
        if (record.overflowValue4pageChs == '满溢')
          return (
            <span style={{ color: '#F2994A' }}>
              {record.overflowValue4pageChs}
            </span>
          )
      },
    },
    {
      title: '回收数量',
      dataIndex: 'num',
      hideInSearch: true,
      maxWidth: 100,
      hideInForm: true,
      sorter: (a, b) => a.num - b.num,
    },
    {
      title: '最近投递时间',
      dataIndex: 'delivertime',
      maxWidth: 100,
      valueType: 'delivertime',
      sorter: (a, b) => new Date(a.delivertime) - new Date(b.delivertime),
    },
    {
      title: '操作',
      dataIndex: 'option',
      valueType: 'option',
      // maxWidth: 300,
      render: (_, record) => (
        <div>
          {/* <a style={{ color: '#19A789' }}  onClick={() => { navigate("/device/detail/",{state:{id:record.branchCode}}) }}>编辑</a>
          <Divider type="vertical" /> */}
          {/* {console.log(record)} */}
          <Popconfirm
            title="确认要关机吗？"
            onConfirm={() => {
              shutDown(record, 'SHUT_DOWN')
            }}
            icon={<QuestionCircleOutlined />}
          >
            <a className={styles.option} style={{ color: '#19A789' }}>
              关机
            </a>
          </Popconfirm>

          <Divider type="vertical" />
          {/* <br /> */}
          <Popconfirm
            title="确认要重启吗？"
            onConfirm={() => {
              shutDown(record, 'RESTART')
            }}
            icon={<QuestionCircleOutlined />}
          >
            <a className={styles.option} style={{ color: '#19A789' }}>
              重启
            </a>
          </Popconfirm>
          <Divider type="vertical" />
          <Popconfirm
            title="确认要打开舱门吗？"
            onConfirm={() => {
              shutDown(record, 'RECYCLE_OPEN')
            }}
            icon={<QuestionCircleOutlined />}
          >
            <a className={styles.option} style={{ color: '#19A789' }}>
              打开回收舱
            </a>
          </Popconfirm>
        </div>
      ),
    },
  ]
  // 地址选择器清除
  function selectAllowClear(type, form) {
    if (type == 'provinceId') {
      setCityList([])
      setDistrictList([])
      setProvinceName([])
      setProvinceName('')
      setCityId('')
      setAreaId('')
      form.resetFields(['provinceId', 'cityId', 'areaId'])
    } else if (type == 'cityId') {
      setCityList([])
      setDistrictList([])
      setCityId('')
      setAreaId('')
      form.resetFields(['cityId', 'areaId'])
    } else if (type == 'areaId') {
      setDistrictList([])
      setAreaId('')
      form.resetFields(['areaId'])
    }
    getDeviceList({})
  }
  // 导出报表
  function outExcel(params) {
    request
      .get({
        method: 'iot.back.fws.iot.term.export',
        biz_content: JSON.stringify({
          provinceName: provinceName,
          cityName: cityId,
          areaName: areaId,
          begin: params.createDate
            ? moment(params.createDate[0]).format('YYYY-MM-DD')
            : '',
          end: params.createDate
            ? moment(params.createDate[1]).format('YYYY-MM-DD')
            : '',
          machineName: params.machineName ? params.machineName : '',
          machineId: params.machineId ? params.machineId : '',
          // machineId: '',
          online: online,
          overflowStatus: overflowStatus,
          page: pageNumber,
          size: pageSize,
        }),
      })
      .then(res => {
        window.open(request.api + '?' + encodeURI(convertObj(res)))
      })
  }
  // Object转Query
  function convertObj(data) {
    var _result = []
    for (var key in data) {
      var value = data[key]
      if (value.constructor == Array) {
        value.forEach(function (_value) {
          _result.push(key + '=' + _value)
        })
      } else {
        _result.push(key + '=' + value)
      }
    }
    return _result.join('&')
  }
  // 订单列表
  const getDeviceList = () => {
    let params = form.getFieldsValue()
    // console.log(localStorage.getItem('user'))
    request
      .post({
        // method: 'iot.back.fws.iot.term.info.page',
        method: 'iot.back.fws.iot.ls.dashboard.machine.list',
        postPage: 'machinePage',
        biz_content: JSON.stringify({
          token: localStorage.getItem('user'),
          provinceName: provinceName,
          cityName: cityId,
          areaName: areaId,
          begin: params.createDate
            ? moment(params.createDate[0]).format('YYYY-MM-DD')
            : '',
          end: params.createDate
            ? moment(params.createDate[1]).format('YYYY-MM-DD')
            : '',
          machineName: params.machineName ? params.machineName : '',
          machineId: params.machineId ? params.machineId : '',
          online: online,
          overflowStatus: overflowStatus,
          page: pageNumber,
          size: pageSize,
        }),
      })
      .then(res => {
        console.log(res)
        setListData(res)
      })
  }
  // 修改预约日期
  const [protectRightsItem, setProtectRightsItem] = useState('')
  const [yDate, setyDate] = useState('')
  const handleCancel = () => setPreviewVisible(false)
  const protectRights = item => {
    setPreviewVisible(true)
    setProtectRightsItem(item)
    form.setFieldsValue({ name: '' })
  }
  const onChange = (date, dateString) => {
    setyDate(dateString)
  }
  function creatPro(value) {
    request
      .post({
        method: 'fws.backend.reserveOrder.updateReserveTime',
        biz_content: JSON.stringify({
          id: protectRightsItem.id,
          reserveTime: yDate,
        }),
      })
      .then(res => {
        message.success(res.data.result.message)
        handleCancel()
        getDeviceList({})
      })
  }
  //
  //取消订单
  const handleCancelOrder = () => setcancelpreviewVisible(false)
  const [cancelItem, setcancelItem] = useState('')
  const cancelPro = value => {
    request
      .post({
        method: 'fws.backend.reserveOrder.cancel',
        biz_content: JSON.stringify({
          id: cancelItem.id,
          cancelReason: value.cancelReason,
        }),
      })
      .then(res => {
        handleCancelOrder()
        getDeviceList({})
      })
  }
  // 机器指令录入
  const shutDown = (record, command) => {
    console.log(record, command)
    request
      .post({
        method: 'iot.back.fws.iot.term.command.input',
        biz_content: JSON.stringify({
          command: command,
          termNo: record.branchCode,
        }),
      })
      .then(res => {
        message.success('操作成功')
        getDeviceList({})
      })
  }
  const reload = () => {
    getDeviceList({})
  }
  const tabList = [
    { key: '', tab: '全部设备' },
    { key: '1', tab: '在线' },
    { key: '0', tab: '离线' },
    { key: '0', tab: '正常' },
    { key: '1', tab: '即将满溢' },
    { key: '2', tab: '满溢' },
  ]
  function subTitle() {
    return (
      <div style={{ fontSize: '20px', color: '#333333', fontWeight: '500' }}>
        <span>总设备数：</span>
        <span style={{ marginRight: '40px' }}>{lsitData.total}</span>
        <span>当前在线数：{lsitData.totalOnline}</span>
        <span>{lsitData.onlineNum}</span>
      </div>
    )
  }
  const span = 5
  return (
    <div className={styles.warp}>
      <PageHeader
        className="site-page-header"
        ghost={false}
        subTitle={subTitle()}
        extra={
          <Form
            labelCol={{ span: 6, offset: 2 }}
            name="search_form"
            style={{ marginTop: '40px' }}
            form={form}
          >
            <Row gutter={24}>
              <Col span={span}>
                <Form.Item name="provinceName" label="省份">
                  <Select
                    showSearch
                    placeholder="省份选择"
                    allowClear
                    optionFilterProp="children"
                    onChange={(e, i) => {
                      if (e) {
                        getAddressList(provinceList[e].id, 'city')
                        setProvinceName(provinceList[e].id)
                        form.resetFields(['cityId', 'areaId'])
                      } else {
                        selectAllowClear('provinceName', form)
                      }
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {provinceList.map((item, index) => (
                      <Option value={item.key} key={index}>
                        {item.areaName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={span}>
                <Form.Item name={['cityId']} label="城市">
                  <Select
                    showSearch
                    className={styles.select_w}
                    placeholder="城市选择"
                    allowClear
                    optionFilterProp="children"
                    onChange={(e, i) => {
                      if (e) {
                        getAddressList(e, 'district')
                        setCityId(i.value)
                        form.resetFields(['areaId'])
                      } else {
                        selectAllowClear('cityId', form)
                      }
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cityList.map((item, index) => (
                      <Option value={item.id} key={index}>
                        {item.areaName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={span}>
                <Form.Item name={['areaId']} label="区/县">
                  <Select
                    showSearch
                    placeholder="区/县选择"
                    allowClear
                    optionFilterProp="children"
                    onChange={(e, i) => {
                      if (e) {
                        setAreaId(i.value)
                      } else {
                        selectAllowClear('areaId', form)
                      }
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {districtList.map((item, index) => (
                      <Option value={item.id} key={index}>
                        {item.areaName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={span}>
                <Form.Item name={['online']} label="状态">
                  <Select
                    showSearch
                    placeholder="请选择"
                    allowClear
                    optionFilterProp="children"
                    onChange={(e, i) => {
                      if (i == undefined) {
                        setOnline('')
                        setOverflowStatus('')
                        return
                      }
                      console.log(i)
                      if (i.children == '在线' || i.children == '离线') {
                        setOnline(tabList[e].key)
                        setOverflowStatus('')
                      } else if (i.children == '全部设备') {
                        setOnline('')
                        setOverflowStatus('')
                      } else {
                        setOnline('')
                        setOverflowStatus(tabList[e].key)
                      }
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {tabList.map((item, index) => (
                      <Option value={item.id} key={index}>
                        {item.tab}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={span}>
                <Form.Item name={['machineId']} label="设备编号">
                  <Input placeholder="请填写" maxLength={32} />
                </Form.Item>
              </Col>
              <Col span={span}>
                <Form.Item name={['machineName']} label="设备点位">
                  <Input placeholder="请填写" maxLength={32} />
                </Form.Item>
              </Col>
              <Col span={span}>
                <Form.Item name={['address']} label="设备地址">
                  <Input placeholder="请填写" maxLength={32} />
                </Form.Item>
              </Col>
              <Col span={span}>
                <Form.Item name={['createDate']} label="创建时间">
                  <RangePicker
                    onChange={e => {
                      getDeviceList({})
                    }}
                    placeholder={['开始日期', '结束日期']}
                  />
                </Form.Item>
              </Col>
              <Col span={24} style={{ textAlign: 'right' }}>
                {/* <Button
                  style={{ backgroundColor: '#19A789', color: '#fff' }}
                  onClick={() => {
                    outExcel(form.getFieldValue())
                  }}
                >
                  Download CSV
                </Button> */}
                <Button
                  type="primary"
                  style={{
                    margin: '0 8px',
                    backgroundColor: '#19A789',
                    color: '#fff',
                  }}
                  onClick={getDeviceList}
                >
                  查询
                </Button>
              </Col>
            </Row>
          </Form>
        }
      />
      <Table
        columns={columns}
        pagination={{
          current: pageNumber,
          pageSize: pageSize,
          pageSizeOptions: [10, 20, 50, 100],
          showSizeChanger: true,
          total: lsitData.total,
          onChange: (page, pageSize) => {
            setPageSize(pageSize)
            setPageNumber(page)
          },
        }}
        dataSource={lsitData.data ? lsitData.data : []}
      />

      <Modal
        visible={previewVisible}
        title={'修改预约日期'}
        footer={null}
        onCancel={handleCancel}
        forceRender
      >
        <Form {...formLayout} form={form} style={{ textAlign: 'center' }}>
          <FormItem
            name="name"
            label="修改时间"
            rules={[{ required: true, message: '请选择修改时间' }]}
          >
            <DatePicker onChange={onChange} />
          </FormItem>
          <Button
            type="primary"
            style={{ marginTop: 30 }}
            onClick={async () => creatPro(await form.validateFields())}
          >
            提交
          </Button>
        </Form>
      </Modal>
      <Modal
        forceRender
        visible={cancelpreviewVisible}
        title={'取消订单'}
        footer={null}
        onCancel={handleCancelOrder}
      >
        <Form {...formLayout} form={form} style={{ textAlign: 'center' }}>
          <FormItem
            name="cancelReason"
            label="取消原因"
            rules={[{ required: true, message: '请输入取消原因！' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
          <Button
            type="primary"
            style={{ marginTop: 30 }}
            onClick={async () => cancelPro(await form.validateFields())}
          >
            提交
          </Button>
        </Form>
      </Modal>
      <CreateForm
        onCancel={() => {
          handleModalVisible(false)
          setRecord('')
        }}
        modalVisible={createModalVisible}
        record={record}
        reload={() => {
          reload()
        }}
      ></CreateForm>
      <UpdateForm
        onCancel={() => {
          handleModalVisibleUp(false)
        }}
        modalVisible={updateModalVisible}
        reload={() => {
          reload()
        }}
      ></UpdateForm>
    </div>
  )
}
export default Device
