import React from 'react'
import styles from './detail.module.css'
import './detail.css'
import { useState, useRef, useEffect } from 'react'
import {
  PageHeader,
  Button,
  Table,
  Tag,
  Space,
  DatePicker,
  Avatar,
  Divider,
  Breadcrumb,
  Tabs,
} from 'antd'
import request from '../../util/request'

export default function BillHeader({ data, stateId }) {
  console.log(data, '000000000000000000000')
  const { alipayUserId, weChatId } = stateId
  console.log(alipayUserId, weChatId)
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    // get_list()
  }, [])
  // 账单数据
  // const get_list = () => {
  //   request
  //     .new_post({
  //       method: 'bankcard/ul.back.pointDetail.userPointDetailPage',
  //       data: {
  //         userId: 11,
  //       },
  //     })
  //     .then(res => {
  //       console.log(res)
  //     })
  //     .catch(res => {
  //       setUserInfo(res.data.data)

  //       console.log(res.data.data)
  //     })
  // }

  const userId = () => {
    if (alipayUserId !== '' && weChatId !== '') {
      return (
        <div style={{ width: '40px', height: '40px' }}>
          <img
            src="https://static.shishangbag.vip/ul/zfb.png"
            alt=""
            srcset=""
          />
          <img
            src="https://static.shishangbag.vip/ul/wx.png"
            alt=""
            srcset=""
          />
        </div>
      )
    } else if (alipayUserId) {
      return (
        <div style={{ width: '40px', height: '40px' }}>
          <img
            style={{ width: '100%', height: '100%' }}
            src="https://static.shishangbag.vip/ul/zfb.png"
            alt=""
          />
        </div>
      )
    } else if (weChatId) {
      return (
        <div>
          <img src="https://static.shishangbag.vip/ul/wx.png" alt="" />
        </div>
      )
    }
  }

  return (
    <div className={styles.headBox}>
      <div
        className={styles.user}
        style={{ alignItems: 'center', display: 'flex' }}
      >
        <Avatar size={64} src={data.avatarUrl} />
        <div
          style={{
            display: 'flex',
            fontSize: '20px',
            color: '#333333',
            fontWeight: '500',
          }}
        >
          <div className={styles.zfbAndWx}>
            <div style={{ display: 'flex' }}>{userId()}</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ marginLeft: 60 }}>用户ID:{data.userId}</span>
            <span style={{ marginLeft: 60 }}>手机号:{data.mobile}</span>
            <span>废宝银行卡号:{data.cardNo}</span>
          </div>
          <span>{data.alipayUserId}</span>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.box}>
          <div className={styles.title2}>银行卡余额</div>
          <div className={styles.title3}>{data.point}</div>
        </div>
        <Divider className={styles.divider} type="vertical" />
        <div className={styles.box}>
          <div className={styles.title2}>银行卡经验值</div>
          <div className={styles.title3}>{data.experience}</div>
        </div>
        <Divider className={styles.divider} type="vertical" />
        <div className={styles.box}>
          <div className={styles.title2}>注册时间</div>
          <div className={styles.title4}>{data.laterConsumeDate}</div>
        </div>
        <Divider className={styles.divider} type="vertical" />
        <div className={styles.box}>
          <div className={styles.title2}>最近消费时间</div>
          <div className={styles.title4}>{data.registerDate}</div>
        </div>
      </div>
    </div>
  )
}
