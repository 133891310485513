import React, { useState, useRef, useEffect } from 'react'
import styles from './index.module.css'
import './detail.css'
import dayjs from 'dayjs'
import { Table } from 'antd'

export default function Bill({ data }) {
  const [dataList, setDataList] = useState([])
  const [total, setTotal] = useState('')
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)

  console.log(data)
  useEffect(() => {
    setDataList(data.page.content)
    setTotal(data.totalPages)
  }, [])

  const columns = [
    {
      title: '账单类型',
      dataIndex: 'pointType',
      key: 'pointType',
      render: text => {
        let bc = '#6fcf97'
        let unbc = '#eb5757'
        return (
          <div
            style={{
              width: '98px',
              height: '30px',
              background: text == '1' ? bc : unbc,
              lineHeight: '30px',
              textAlign: 'center',
              color: '#ffffff',
              borderRadius: '5px',
              fontSize: 14,
            }}
          >
            {text === '1' ? '收入' : '支出'}
          </div>
        )
      },
    },
    {
      title: '账单详情',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: '账单金额',
      dataIndex: 'point',
      key: 'point',
    },
    {
      title: '当前金额',
      dataIndex: 'pointAfter',
      key: 'pointAfter',
    },
    {
      title: '账单时间',
      render: create => dayjs(create.create).format('YYYY-MM-DD-MM'),
      key: 'createDate',
    },
  ]

  return (
    <div className={styles.warp}>
      <div>
        <Table
          pagination={{
            current: pageNumber,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 50, 100],
            total: total,
            onChange: (page, pageSize) => {
              setPageSize(pageSize)
              setPageNumber(page)
            },
          }}
          columns={columns}
          dataSource={dataList}
        />
      </div>
    </div>
  )
}
