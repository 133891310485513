import styles from './detail.module.css';
// import  './detail.css';
import React,{useState,useRef,useEffect} from 'react'
import { PageHeader,Button,Table, Tag, Space,DatePicker,Avatar ,Divider ,Breadcrumb } from 'antd';
import request from '../../util/request';
import { BrowserRouter as Router, Route, Routes , useNavigate,useLocation } from 'react-router-dom'

export default function DeviceDetail() {
  const { RangePicker } = DatePicker;
  let navigate = useNavigate();
  let location = useLocation()
  const childRef = useRef();
  const [pageSize,setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1)
  const [total, setTotal] = useState(0)
  const [deviceInfo,setDeviceInfo] = useState({})
  const [data,setData] = useState([])
  useEffect(() => {
   
    getDeviceInfo(location.state.id)

  },[pageSize,pageNumber])
 
  const getDeviceInfo = (id) => {
    request.post({
      'method': 'iot.back.fws.iot.ls.dashboard.machine.info',
      "biz_content": JSON.stringify({
        "machineId": id,
        "page":pageNumber,
        "size":pageSize
      })
    }).then((res) => {
      if (res.data.code == 10000) {
        setDeviceInfo(res.data.result)
        setData(res.data.result.lsDashboardMachineInfoResultMPage.content)
        setTotal(res.data.result.lsDashboardMachineInfoResultMPage.totalElements)
      }
    })

  }
  

  const columns = [
    
    {
      title: '投递结果',
      dataIndex: 'waste',
      key: 'key',
      render: (text) => {
          let bc = 'linear-gradient(90deg, #1aa88a 0%, #62ccb7 100%)'
          let unbc = 'linear-gradient(90deg, #8e9eab 0%, #b9c4cb 100%)'
          return <div style={{
              width: '98px', height: '30px',
              background:text == '塑料瓶'?bc:unbc,
              lineHeight: '30px',
              textAlign: 'center',
              color: '#ffffff',
              borderRadius: '5px',
              fontSize: 14
          }}
          >{text}</div>
      }
    },
    {
      title: '投递机器',
      dataIndex: 'branchName',
      key: 'key',
     
    },
    {
      title: '投递时间',
      key: 'key',
      dataIndex: 'createtime',
      
    },
    {
      title: '领券信息',
      key: 'key',
      dataIndex: 'bannerDetails',
      
    },
    
  ];

  function Heade() {
    return(
        <div className={styles.headBox} >
            <div className={styles.user} >
                <Avatar size={64} src="https://sbag-small.oss-cn-huhehaote.aliyuncs.com/upload/img/web/ito/machine3.png" />
                <div style={{fontSize:"20px",color:"#333333",fontWeight:"500"}} >
                    <span  >设备ID：</span>
                    <span>{location.state.id}</span>
                </div>
            </div>
            <div className={styles.info} >
                <div className={styles.box}>
                    <div className={styles.title2} >设备名称</div>
                    <div className={styles.title3} >{deviceInfo.branchName}</div>
                </div>
                <Divider className={styles.divider} type='vertical' />
                <div className={styles.box}>
                    <div className={styles.title2} >设备地区</div>
                    <div className={styles.title3} >{deviceInfo.branchAddress}</div>
                </div>
                <Divider className={styles.divider} type='vertical' />
                <div className={styles.box}>
                    <div className={styles.title2} >创建时间</div>
                    <div className={styles.title4} >{deviceInfo.branchCreatDate}</div>
                </div>
                <Divider className={styles.divider} type='vertical' />
                <div className={styles.box}>
                    <div className={styles.title2} >最近投递时间</div>
                    <div className={styles.title4} >{deviceInfo.lastDeliveryDate}</div>
                </div>
            </div>
        </div>
    )
  }
  return (
    <div  className={styles.container}>
    
      <div className={styles.product}>
        <div className={styles.warp} >
        <PageHeader
            className="site-page-header"
            // title="用户详情"
            breadcrumb={(
                <Breadcrumb>
                    <Breadcrumb.Item  onClick={() => { navigate(-1)}} >
                    <a>设备管理</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>设备详情</Breadcrumb.Item>
                </Breadcrumb>
            )}
            ghost={false}
            // subTitle={subTitle()}
            extra={Heade()}
        />
        <div className={styles.tableTitle} >投递记录</div>
        <Table 

          pagination={{
            current:pageNumber,
            pageSize:pageSize,
            pageSizeOptions:[10,20,50,100],
            showSizeChanger:true,
            total:total,
            onChange:(page, pageSize) => {
            setPageSize(pageSize)
            setPageNumber(page)
          }}}
          columns={columns} 
          dataSource={data} 
        />
        </div>
      </div>
       {/* <Footer/> */}
    </div>
  )
}
  