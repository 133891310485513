
import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button, Divider, Dropdown, Menu, message, Input, Tabs, Modal, Select, Alert } from 'antd';
import UploadImg from "../../component/uploadImg/Upload"
import style from "../index.less"
import request from '../../../util/request';
const { Option } = Select;
const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 6 },
};
const validateMessages = {
  required: '${label} 必填',
  types: {
    email: '${label} is not validate email!',
    number: '${label} is not a validate number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
const CreateForm = (props) => {
  const { modalVisible, onCancel } = props;
  const [form] = Form.useForm();
  const [itemId, setItemId] = useState();
  const [detail, setdetail] = useState({})

  const [provinceList,setProvinceList] = useState([])
  const [cityList,setCityList] = useState([])
  const [districtList,setDistrictList] = useState([])
  const [provinceId,setProvinceId] = useState("")
  const [cityId,setCityId] = useState("")
  const [areaId,setAreaId] = useState("")
  const [provinceName,setProvinceName] = useState("")
  const [cityName,setCityName] = useState("")
  const [areaName,setAreaName] = useState("")

  const [manList,setManList] = useState([])
  useEffect(() => {
    if (props.record.id) {//完成订单
      form.resetFields()
      setdetail(props.record);
      getAddressList(props.record.provinceId,"city")
      getAddressList(props.record.cityId,"district")
      setProvinceId(props.record.provinceId)
      setCityId(props.record.cityId)
      setAreaId(props.record.areaId)
      setProvinceName(props.record.provinceName)
      setCityName(props.record.cityName)
      setAreaName(props.record.areaName)
      form.setFieldsValue({...props.record})
      setItemId(props.record.id);
      if(props.record.scenePictures) {
        editImg(props.record.scenePictures)
      } else {
        setFileList([])
      }
      
    } else {
      form.resetFields();
    }
    getAddressList(0,'province')
    manufacturerList()
    
  }, [props.record.id]);

  
  const getAddressList= (id,level) => {

    request.post({
      'method': 'area.allChildren',
      "biz_content": JSON.stringify({
        id:id
      })
    }).then(res => {
      if(res.data.code == 10000){
        if(level == 'province'){
          setProvinceList(res.data.result)
        }else if(level == 'city'){
          setCityList(res.data.result)
        }else if(level == 'district'){
          setDistrictList(res.data.result)
        }
      }
    })
    
  }

  const manufacturerList = () => {

    request.post({
      'method': 'iot.user.iotHardware.list',
        "biz_content": JSON.stringify()
    }).then(res => {
      if(res.code == 10000){
        setManList(res.data.result)
      }
    })
    
  }

  // 编辑时图片处理
  const editImg = (picturesList) => {
    if(!picturesList){
      return
    }
    let fileList = []
    if(picturesList.indexOf(",") == -1 ){
      fileList.push({
        uid: picturesList,
        name: "img",
        status: 'done',
        url: picturesList,
        response: { imgUrl: picturesList }
      })
    }else{
      let list = picturesList.split(",")
      list.map(e => {
        fileList.push({
          uid: e,
          name: "img",
          status: 'done',
          url: e,
          response: { imgUrl: e }
        })
      })
    }
    setFileList(fileList)
}
  const onFinish = values => {
    let scenePictures = fileList.map(e => {
        return e.response.imgUrl
    })
    let df = form.getFieldsValue()

    request.post({
      "method": itemId ? "iot.back.fws.iot.insert" : "iot.back.fws.iot.insert",
      "biz_content": JSON.stringify({
        brancheName:df.brancheName,
        iotHardwareId:df.iotHardwareId,
        address:df.address,
        branchCode:df.branchCode,
        provinceId,
        cityId,
        areaId,
        provinceName,
        cityName,
        areaName,
        scenePictures:scenePictures.toString()
      })
    }).then(res => {
      if (res.data.code == 10000) {
        message.success('提交成功');
        props.onCancel();
        props.reload();
      } else {
        message.error(res.data.msg);
      }
    })

  };

  const [fileList, setFileList] = useState([])
  const handleChange = (fileList) => {
    setFileList(fileList)
  };
  return (
    <Modal
      // destroyOnClose
      forceRender
      getContainer={false}
      title={props.record.id?"编辑":"新增"}
      visible={modalVisible}
      onOk={() => onFinish()}
      onCancel={() => {
        setFileList([])
        props.onCancel()
      }}
      reload={() => props.reload()}
      footer={null}
    >
      {/* {props.children} */}
      <Form form={form} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages} labelCol={{ span: 6 }}>
        <Form.Item name={['brancheName']} label="设备点位" rules={[{ required: true }]}  >
          <Input maxLength={32} style={{ width: '300px' }} />
        </Form.Item>
        <Form.Item name={['iotHardwareId']} label="设备厂家" rules={[{ required: true }]}  >
          <Select
            style={{ width: '300px' }} 
            showSearch
            placeholder="请选择"
            optionFilterProp="children"
            onChange={ (e,i) => {
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {manList.map((item,index) => <Option  value={item.id} key={index} >{item.factory}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item name={['provinceId']} label="省份" rules={[{ required: true }]}  >
          <Select
            style={{ width: '300px' }} 
            showSearch
            placeholder="请选择"
            optionFilterProp="children"
            onChange={ (e,i) => {
              getAddressList(e,"city")
              form.resetFields(["cityId","areaId"])
              setProvinceName(i.children)
              setProvinceId(e)
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {provinceList.map((item,index) => <Option  value={item.id} key={index} >{item.areaName}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item name={['cityId']} label="城市" rules={[{ required: true }]}  >
          <Select
            style={{ width: '300px' }} 
            showSearch
            placeholder="请选择"
            optionFilterProp="children"
            onChange={ (e,i) => {
              getAddressList(e,"district")
              form.resetFields(["areaId"])
              setCityName(i.children)
              setCityId(e)
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {cityList.map((item,index) => <Option  value={item.id} key={index} >{item.areaName}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item name={['areaId']} label="区/县" rules={[{ required: true }]}  >
          <Select
            showSearch
            style={{ width: '300px' }} 
            placeholder="请选择"
            optionFilterProp="children"
            onChange={(e,i) => {
              setAreaName(i.children)
              setAreaId(e)
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

            }
          >
            {districtList.map((item,index) => <Option  value={item.id} key={index} >{item.areaName}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item name={['address']} label="详细地址" rules={[{ required: true }]}  >
          <Input maxLength={32} style={{ width: '300px' }} />
        </Form.Item>
        <Form.Item name={['branchCode']} label="设备编号" rules={[{ required: true }]}  >
          <Input maxLength={10} style={{ width: '300px' }} />
        </Form.Item>
        <Form.Item  name={['mainPic']} label="图片" rules={[{ required: false }]}>
            <UploadImg length={3} onChange={handleChange} fileList={fileList} />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit"> 确定 </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateForm

