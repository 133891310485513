
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom'
export default function NotFound () {
    const navigate = useNavigate();
    function backHome(){
        navigate('/home/', {replace: true});
    }
    return(
        <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button onClick={backHome} type="primary">返回首页</Button>}
    />
    )
}