import styles from './index.module.css'
import React, { useState, useRef, useEffect } from 'react'
import { PageHeader, Button, Table, Tag, Space, DatePicker, Select } from 'antd'
import request from '../../util/request'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom'
import moment from 'moment'

// iot.back.fws.iot.ls.dashboard.user.register.list

export default function Registered() {
  const { Option } = Select
  const { RangePicker } = DatePicker
  let navigate = useNavigate()
  const childRef = useRef()
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [data, setData] = useState({})
  const [list, setList] = useState([])
  const [userType, setUserType] = useState(null)
  const [gender, setGender] = useState(null)
  useEffect(() => {
    registerList()
  }, [pageSize, pageNumber, startDate, endDate, userType, gender])
  const registerList = () => {
    request
      .post({
        method: 'iot.back.fws.iot.ls.dashboard.user.register.list',
        biz_content: JSON.stringify({
          begin: startDate,
          end: endDate,
          page: pageNumber,
          size: pageSize,
          usertype: userType,
          gender: gender,
        }),
      })
      .then(res => {
        console.log(res)
        if (res.data.code == 10000) {
          let list = res.data.result.content.map((e, i) => {
            e.key = i
            return e
          })
          setList(list)
          setData(res.data.result)
        }
      })
  }

  function goDetails(record) {
    console.log(record)
    navigate('/user/detail/', {
      state: {
        id: record.userId,
        alipayUserId: record.alipayUserId,
        weChatId: record.weChatId,
      },
    })
  }
  const changeDate = value => {
    if (value) {
      setStartDate(moment(value[0]._d).format('YYYY-MM-DD'))
      setEndDate(moment(value[1]._d).format('YYYY-MM-DD'))
    } else {
      setStartDate(moment(null))
      setEndDate(moment(null))
    }
  }

  const columns = [
    {
      title: '用户ID',
      dataIndex: 'userId',
      key: 'key',
      render: (text, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              goDetails(record)
            }}
          >
            {text}
          </a>
        </Space>
      ),
    },
    {
      title: '平台',
      key: 'key',
      render(h) {
        let zfb = (
          <div style={{ width: '40px', height: '40px' }}>
            <img
              style={{ width: '100%', height: '100%' }}
              src="https://static.shishangbag.vip/ul/zfb.png"
              alt=""
            />
          </div>
        )
        let wx = (
          <div style={{ width: '40px', height: '40px' }}>
            <img
              style={{ width: '100%', height: '100%' }}
              src="https://static.shishangbag.vip/ul/wx.png"
              alt=""
            />
          </div>
        )
        return (
          <div style={{ display: 'flex' }}>
            <div>{h.alipayUserId !== '' ? zfb : ''}</div>
            <div>{h.weChatId !== '' ? wx : ''}</div>
          </div>
        )
      },
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      key: 'key',
    },
    {
      title: '性别',
      dataIndex: 'gender',
      key: 'key',
      render: gender => {
        // return gender == '1' ? '男' : '女'
        if (gender == '1') {
          return '男'
        } else if (gender == '2') {
          return '女'
        } else {
          return '未知'
        }
      },
    },

    {
      title: '地区',
      dataIndex: 'province',
      key: 'key',
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'key',
    },
    {
      title: '废宝银行卡号',
      dataIndex: 'cardNo',
      key: 'key',
    },
    {
      title: '银行卡经验值',
      dataIndex: 'experience',
      key: 'key',
    },
    {
      title: '最近投递时间',
      key: 'key',
      dataIndex: 'delivertime',
      sorter: (a, b) => new Date(a.delivertime) - new Date(b.delivertime),
    },
  ]

  function subTitle() {
    return (
      <div style={{ fontSize: '20px', color: '#333333', fontWeight: '500' }}>
        <span>用户总数：</span>
        <span>{data.totalElements}</span>
      </div>
    )
  }

  const platformChange = value => {
    setUserType(value)
  }
  const genderChange = value => {
    setGender(value)
  }

  return (
    <div id="userlist" className={styles.container}>
      <div className={styles.product}>
        <div className={styles.warp}>
          <PageHeader
            className="site-page-header"
            ghost={false}
            subTitle={subTitle()}
            extra={[
              <div>
                <span>平台 &nbsp; &nbsp;</span>
                <Select
                  defaultValue="0"
                  onChange={platformChange}
                  style={{ width: 120 }}
                >
                  <Option value="1">微信</Option>
                  <Option value="2">支付宝</Option>
                  <Option value="0">全部</Option>
                </Select>
                <span style={{ marginLeft: '50px' }}>性别 &nbsp; &nbsp;</span>
                <Select
                  onChange={genderChange}
                  defaultValue=""
                  style={{ width: 120 }}
                >
                  <Option value="1">男</Option>
                  <Option value="2">女</Option>
                  <Option value="">全部</Option>
                </Select>
                <RangePicker
                  style={{ marginLeft: '50px' }}
                  key="1"
                  onChange={value => {
                    changeDate(value)
                  }}
                  placeholder={['开始日期', '结束日期']}
                />
              </div>,
              // <Button type="primary" key="2">
              //   Download CSV
              // </Button>,
            ]}
          />
          <Table
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              total: data.totalElements,
              onChange: (page, pageSize) => {
                console.log(data)
                setPageSize(pageSize)
                setPageNumber(page)
              },
            }}
            columns={columns}
            dataSource={list}
          />
        </div>
      </div>

      {/* <Footer/> */}
    </div>
  )
}
