import './App.css';
import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import {  Route, Routes,useNavigate } from 'react-router-dom'
import React, {useEffect, useState,} from "react";
import Header from "../src/pages/component/Header"
import router from './util/router.js'
import NotFound from './pages/NotFound'
function App() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState('Content of the modal');
  useEffect(() => {
    changeLogin()
  },[])

  // 判断是否登录
  function changeLogin(){
    let token = localStorage.getItem('user') ? localStorage.getItem('user') : '';
    if(!token){
      navigate('/', {replace: true});
    }
  }
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false)

    window.localStorage.clear()
    window.sessionStorage.clear()
    navigate('/', {replace: true});


  };

  const handleCancel = () => {  setVisible(false)};

  return (
    <Routes>
        {
          router.map((item,key) => {
            return (
              <Route key={key} path={item.path}  element={   !item.auth? < item.component/>:(
                item.auth?  (
                <div>
                  <Modal
                    title="提示"
                    visible={visible}
                    onOk={handleOk}
                    cancelText={(<div style={{color:"#fff"}} >取消</div>)}
                    okText={(<div style={{color:"#fff"}} >确认</div>)}
                    onCancel={handleCancel}
                  >
                    <p>是否要退出登录？</p>
                  </Modal>
                  <Header showModal={showModal} />
                  < item.component/>
                </div>
              ): < item.component/>)}/>
            ) 
          })                 
        }
        <Route path='*' element={<NotFound/>}></Route>
    </Routes>
  );
}
export default App;

