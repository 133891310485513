import { Model } from "echarts";

function addZero(value) {
     value =  value>=10?value:"0"+value;
     return value
}

// 获取近一个月
const getNearOneMonth = () => {
    let end = new Date();
    let year = end.getFullYear();
    let month = end.getMonth() + 1;//0-11表示1-12月
    let day = end.getDate();
    let dateObj = {};
    dateObj.endDate = year + '-' + addZero(month)  + '-' + addZero(day) ;
    let endMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
    if(month - 1 <= 0){ //如果是1月，年数往前推一年<br>　　　　
        dateObj.startDate = (year - 1) + '-' + 12 + '-' + addZero(day)  ;
    }else{
        let startMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();
        if(startMonthDay < day){    //1个月前所在月的总天数小于现在的天日期
            if(day < endMonthDay){        //当前天日期小于当前月总天数
                dateObj.startDate = year + '-' + addZero((month - 1)) + '-' + addZero((startMonthDay - (endMonthDay - day))) ;
            }else{
                dateObj.startDate = year + '-' + addZero((month - 1)) + '-' + addZero(startMonthDay) ;
            }
        }else{
            dateObj.startDate = year + '-' + addZero((month - 1))  + '-' + addZero(day) ;
        }
    }
    return dateObj
}

const getNeerOneWeek = () => {
    var end = new Date();
    var year = end.getFullYear();
    var month = end.getMonth() + 1;//0-11表示1-12月
    var day = end.getDate();
    var dateObj = {};
    dateObj.endDate = year + '-' + addZero(month) + '-' +addZero(day) ;
    if (day - 7 <= 0) {   //如果在当月7日之前
        var startMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();    //1周前所在月的总天数
        if (month - 1 <= 0) { //如果在当年的1月份
        dateObj.startDate = (year - 1) + '-' + 12 + '-' + addZero((31 - (7 - day))) ;
        } else {
        dateObj.startDate = year + '-' + addZero((month - 1))  + '-' + addZero((startMonthDay - (7 - day))) ;
        }
    } else {
        dateObj.startDate = year + '-' +addZero(month)  + '-' +addZero((day - 7));
    }
    return dateObj



}

export {getNearOneMonth,getNeerOneWeek}